<template>
  <div class="text-center">
    <v-overlay :value="overlay" absolute opacity="1" z-index="2" color="white">
      <v-progress-circular color="primary" indeterminate size="70"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  computed: {
    overlay() {
      return this.$store.state.GlobalModules.loader.overlay
    }
  }
}
</script>